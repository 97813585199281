/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Loads a responsive, accurate page preview in an iframe:
//
// 1) Calculates the zoom level of the iframe based on its parent container's relative width
// 2) Uses the calculated zoom level to apply the correct height and width of the iframe element.

// Usage:
// Assign data-toggle='iframe-preview' to the iframe's parent div
// Add the 'fade' css class to the iframe parent container (it's made invisible during load, and then faded in via adding an 'show' class once loaded and resized)

// Example usage:
// .responsive-iframe-container.fade{ data: { toggle: 'iframe-preview' } }
//   %iframe.responsive-iframe{:src => project_path(project, iframe_preview: 'true') }

// when page contains content scrolls, specify scroll container class
// .responsive-iframe-container.fade{ data: { toggle: 'iframe-preview' scroll_container: 'event-content'} }

class IframePreview {
  constructor(el) {
    this.el = el;
    this.iframe = this.el.find('iframe')[0];
    this.zoom = (this.el.width() / $(document).width());
    this.minHeight = this.el.data('minHeight') || 0;

    this.el.find('iframe').on('load', e => {
      this.setPreviewAndWidthCSS();
      return setTimeout(() => {
        this.setPreviewHeight();
        return this.el.addClass('show');
      }
      , 100);
    }); //allows height measurement to occur *after* the responsive images have been resized.
  }

  setPreviewHeight() {
    const {
      body
    } = this.iframe.contentDocument;
    const html = this.iframe.contentDocument.documentElement;
    let bodyScrollHeight = body.scrollHeight;

    if (this.el.data('scrollContainer')) {
      const scrollContainer = this.iframe.contentWindow.document.getElementsByClassName(this.el.data('scrollContainer'))[0];
      if (scrollContainer && (scrollContainer.scrollHeight > scrollContainer.offsetHeight)) {
        bodyScrollHeight = scrollContainer.scrollHeight;
      }
    }

    const height = Math.max(bodyScrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight, this.minHeight);
    this.iframe.height = height;
    return this.el.height(height * this.zoom);
  }

  setPreviewAndWidthCSS() {
    this.iframe.width = document.body.scrollWidth;

    if ($('html.no-smil:not(.no-flexboxtweener)').length) { // IE10 & 11
      return this.iframe.style.zoom = this.zoom;
    } else { // everything else
      return this.iframe.style.transform = `scale(${this.zoom})`;
    }
  }

  resize() {
    this.setPreviewAndWidthCSS();
    return this.setPreviewHeight();
  }
};

$(document).on('turbo:load', function() {
  const el = $("[data-toggle='iframe-preview']");

  if (!el.length) { return; }
  return el.each(function() {
    let inst;
    return inst = new IframePreview($(this));
  });
});
