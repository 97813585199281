document.addEventListener('turbo:submit-start', (event) => {
  // Rails UJS disable_with feature not working for turbo forms.
  // Adding this custom hook to disable submit button while form submit is in prgoress.
  const submitter = event.detail.formSubmission.submitter;
  // here submitter is element responsible for the submission
  submitter.toggleAttribute('disabled', true);
  submitter.classList.add('turbo-submit-disabled');
})

document.addEventListener('turbo:before-cache', () => {
  // re-enabling form submit buttons after submit process is complete and before caching new page.
  document.querySelectorAll('.turbo-submit-disabled').forEach((button) => {
    button.toggleAttribute('disabled', false);
    button.classList.remove('turbo-submit-disabled');
  });
});
