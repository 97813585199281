class AuctionWarning {
  constructor(container) {
    this.container = container;
    this.auctionEndAtField = container.find("#auction_end_at");

    this.showEndAtExtensionWarning();
  }

  showEndAtExtensionWarning() {
    // converts the selected auction end_at into a definite format for comparison
    let initSelectedEndAt = moment.utc(new Date(this.auctionEndAtField.val()));
    // current datetime to be compared with initSelectedEndAt
    let currentTime = moment.utc();
    let endsAtWarningBlock = $("div[data-describe='ends_at_warning']")

    // we only need to check whether to show the end at warning message if the auction is closed, i.e. the end_at datetime is in the past
    if(initSelectedEndAt < currentTime) {
      this.auctionEndAtField.on('dp.change', function(dpEvent) {
        let selectedTime = moment.utc(new Date($(this).val()));
        // if end_at is changed to a future datetime or the date with in the current time, it shows a warning alert
        if(selectedTime > initSelectedEndAt) {
          endsAtWarningBlock.removeClass("d-none");
        }
        else {
          endsAtWarningBlock.addClass("d-none");
        }
      });
    }
  }
};

$(document).on('turbo:load', function() {
  const edit_auction_form = $('.edit_auction');
  if (!(edit_auction_form.length > 0)) { return; }
  new AuctionWarning(edit_auction_form);
});
