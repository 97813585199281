$(document).on('click', '.modal-footer a.submit', function(event) {
  event.preventDefault();
  event.stopPropagation();

  var form = $(this).closest('.modal').find('form');
  // Adds a custom confirm to resolve https://networkforgood.atlassian.net/browse/AIO-1143
  // If the submit has a confirm field, it will be used to confirm the action before submitting the form.
  // This is a workaround, the reason for doing this is that somehow the rails_ujs package does not work with the confirm field,
  // Form will get submitted even if we cancelled the confirmation. So as a work around we add a custom confirm field as nfg-confirm
  // and if it is present, we will use it to confirm the action.
  var message = $(this).data('nfg-confirm');
  if (message) {
    if (confirm(message)) {
      submitForm(form);
    } else {
      // This is also a work around, if the user cancelled the confirmation, we need to prevent the form from submitting and enable the submit button.
      setTimeout((() => {
        Rails.enableElement($(this)[0]);
      }), 15);
      return false;
    }
  } else {
    submitForm(form);
  }
});

function submitForm(form) {
  if (form.data('remote')) {
    Rails.fire(form[0], 'submit');
  }
  else {
    form.submit();
  }
}

$(document).on('shown.bs.modal', function(e) {
  const modal = $(e.target);
  if (!modal.hasClass('no-auto-focus')) {
    modal.find(':text, textarea').first().focus();
  }
});
