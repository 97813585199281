import dateTimePicker from 'eonasdan-bootstrap-datetimepicker';

global.initDateTimePicker = function() {
  const inputs = $('input.datetime-picker, input.date-picker, input.time-picker');
  if (!(inputs.length > 0)) { return; }
  return inputs.each((index, input) => new DateTimePicker($(input)));
}

export default class DateTimePicker {
  constructor(input) {
    this.input = input;
    this.input[0].readOnly = true;
    this.input.datetimepicker(this.options());
    this.input.on('dp.show', event => {
      $('.bootstrap-datetimepicker-widget').addClass('fs-ignore-rage-clicks'); // makes fullstory ignore "rage clicks" on the datetimepicker
    });
  }

  options() {
    const options = this.input.data('options') || {};
    if (!options.format) { options.format = this.defaultFormat(); }
    options.useCurrent = false;
    options.ignoreReadonly = true;
    options.icons = {
      time:"fa fa-clock-o",
      date:"fa fa-calendar",
      up:"fa fa-caret-up",
      down:"fa fa-caret-down",
      previous:"fa fa-caret-left",
      next:"fa fa-caret-right",
      today:"fa fa-crosshairs",
      clear:"fa fa-trash-o",
      close:"fa fa-times"
    }
    options.sideBySide = true;
    // options.debug = true;
    return options;
  }

  defaultFormat() {
    if (this.input.hasClass('datetime-picker')) {
      return 'Y-MM-DD hh:mm A';
    } else if (this.input.hasClass('time-picker')) {
      return 'hh:mm A';
    } else {
      return 'Y-MM-DD';
    }
  }
};

$(document).on('turbo:load', function() {
  initDateTimePicker();
});
