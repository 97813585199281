class AuctionFormInteractions {
  constructor(container) {
    this.container = container;
    this.title_field = container.find("#auction_title");
    this.short_name_field = container.find('#auction_short_name');

    this.addShortNameField();
  }

  addShortNameField() {
    this.title_field.on('keyup', event => {
      let title_value = this.title_field.val();

      // removes all the special characters from the title if any
      let short_name = title_value.replace(/[^a-zA-Z0-9-]/g, ' ');

      // replaces the white spaces with hyphes and making the string to lowercase
      short_name = short_name.replace(/\s+/g, '-').toLowerCase();

      this.short_name_field.val(short_name);
    });
  }
};

$(document).on('turbo:load', function() {
  const auction_form = $('#auction_form.new_auction');
  if (!(auction_form.length > 0)) { return; }
  new AuctionFormInteractions(auction_form);
});
