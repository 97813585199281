// Manages event handlers for a 'Save and Add Another' button in form views

class SaveAndAddAnother {
  constructor(button) {
    this.button = button;
    this.form = this.button.closest('form');
    this.form.find('input[type="submit"], button[type="submit"]').on('click', event => {
      this.form.find(':hidden[name="save_and_add_another"]').remove();
      if ($(event.currentTarget).hasClass('save_and_add_another')) {
        this.form.prepend('<input type="hidden" name="save_and_add_another" value="1">');
      }
    });
  }
};

$(document).on('turbo:load', function() {
  const buttons = $('button.save_and_add_another');
  if (!(buttons.length > 0)) { return; }
  buttons.each(function() {
    new SaveAndAddAnother($(this));
  });
});
