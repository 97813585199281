// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Need to be able to load rails_ujs in sprockets and webpacker for payments gem
if (!window._rails_loaded) { import("@rails/ujs").start() }

import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'moment'
import '../src/side_nav';
import '../src/auction';
import '../src/auction_warning';
import '../src/file_input';
import '../src/input_masks';
import '../src/auction_item';
import '../src/select2';
import '../src/auto_complete';
import '../src/modals';
import '../src/iframe_preview';
import '../turbo/turbo-helper';
import '../src/save_and_add_another';
import DateTimePicker from '../src/datetimepicker';
import "@hotwired/turbo-rails"
import ahoy from "ahoy.js"
import 'handlebars'
import 'inputmask';

import "styles/application"

// import {} from '';

require("trix")
require("@rails/actiontext")

global.Rails = Rails;
ActiveStorage.start()
