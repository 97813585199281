import 'select2'

global.initSelect2Plugin = function() {
  if (!$('select.select2').length) {
    return;
  }
  $('select.select2').select2()
}

global.initSelect2SearchPlugin = function(parent){
  if (!$('select.select2-search').length) {
    return;
  }

  $('select.select2-search').each(function() {
    $(this).select2({
      minimumInputLength: 3,
      ajax: {
        processResults: function (data) {
          return {
            results: $.map(data, function (item) {
              return {
                text: item,
                id: item
              }
            })
          };
        }
      }
    });
  });
}

$(document).on('turbo:load', function() {
  let doc = $(document);
  let body = $('body');
  initSelect2Plugin();
  initSelect2SearchPlugin(body);
  // use 'shown' tense on modal event
  // otherwise, no guarantee that the select menu will be on the page yet
  doc.on('shown.bs.modal', '.modal', function(e) {
    initSelect2Plugin();
    initSelect2SearchPlugin($('.modal-body'));
    // remove negative tab index, per select2 docs on modals
    $('.modal').attr('tabindex', '');
  });

  // Likewise with the shown.bs.modal, the same applies here for re-initializing on body
  // after the a tooltipped element is removed from the page
  doc.on('hidden.bs.modal ajax:success', function(e) {
    initSelect2Plugin();
    initSelect2SearchPlugin(body);
  });
});
