class AuctionItem {
  constructor(container) {
    this.container = container;
    this.quantity_field = container.find("#item_quantity");
    this.quantity_label = container.find("#quantity_label");
    this.quantity_wrapper = container.find("#quantity_wrapper");
    this.type_field = container.find("input:radio[name='item[item_type]']");
    this.quantity_warning = container.find("#quantity_warning");
    this.item_type_warning = container.find("#item_type_warning");
    this.priceless = container.find("#item_priceless");
    this.fair_market_value = container.find("#item_fair_market_value");
    this.fair_market_value_priceless = container.find("#item_fair_market_value_priceless");
    this.bid_increment_wrapper = container.find("#bid_increment_wrapper");
    this.quantity_wrapper = container.find("#quantity_wrapper");
    this.purchase_price_wrapper = container.find("#purchase_price_wrapper");
    this.starting_bid_amount_wrapper = container.find("#starting_bid_amount_wrapper");
    this.item_starting_bid_amount = container.find("#item_starting_bid_amount");
    this.item_minimum_bid_increment = container.find("#item_minimum_bid_increment");
    this.bid_increment_type = container.find("#item_bid_increment_type");
    this.bid_increment_type_wrapper = container.find("#bid_increment_type_wrapper");
    this.item_availability = container.find("input:radio[name='item_availability']");
    this.date_wrapper = container.find("#date_wrapper");
    this.unlimited_quantity_help = container.find("#unlimited_quantity_help");
    this.item_start_at = container.find("#item_start_at");
    this.item_end_at = container.find("#item_end_at");

    this.bindItemTypeChanges();
    this.bindQuantityFieldName();
    this.bindfairMarketValue();
    this.bindItemTypeWarning();
    this.bindQuantityWarning();
    this.bindfairMarketValueChanges();
    this.loadItemModeValue();
    this.bindDateFields();
  }

  bindItemTypeChanges() {
    this.updateFormLabels(this.itemTypeValue());

    this.type_field.on('change', event => {
      const itemType = this.itemTypeValue();
      this.updateFormLabels(itemType);

      if (itemType == 'live') {
        // DO NOT display these fields when adding "LIVE" auction item type: Bid increment, Purchase price, Quantity
        this.hideElement(this.bid_increment_wrapper);
        this.hideElement(this.quantity_wrapper);
        this.hideElement(this.purchase_price_wrapper);
        this.showElement(this.starting_bid_amount_wrapper);
        this.hideElement(this.bid_increment_type_wrapper);
        this.hideElement(this.unlimited_quantity_help);
      } else if (itemType == 'fixed_price') {
        // DO NOT display these fields when adding "FIXED" auction item type: Minimum bid amount, Bid increment
        this.hideElement(this.bid_increment_wrapper);
        this.showElement(this.quantity_wrapper);
        this.showElement(this.purchase_price_wrapper);
        this.hideElement(this.starting_bid_amount_wrapper);
        this.hideElement(this.bid_increment_type_wrapper);
        this.showElement(this.unlimited_quantity_help);
      } else {
        this.showElement(this.bid_increment_wrapper);
        this.showElement(this.quantity_wrapper);
        this.showElement(this.purchase_price_wrapper);
        this.showElement(this.starting_bid_amount_wrapper);
        this.hideElement(this.unlimited_quantity_help);
        this.setItemModeValue();
      }
    });
  }

  bindQuantityFieldName() {
    this.type_field.on('change', event => {
      this.quantity_label.text(this.itemTypeValue() == 'silent' ? 'Number of Winners' : 'Quantity')
    });
  }

  // Enable/Disable and assign value for fair_market_value field based on the priceless selection
  bindfairMarketValue() {
    this.priceless.on('change', event => {
      this.fair_market_value.val("0.00");
      this.fair_market_value_priceless.toggleClass("d-none");
      this.fair_market_value.toggleClass("d-none");
    });
  }

  bindItemTypeWarning() {
    this.hideElement(this.item_type_warning);

    this.type_field.on('change', event => {
      this.checkItemHasBids(this.type_field.filter(':checked'));
    });
  }

  bindQuantityWarning() {
    this.hideElement(this.quantity_warning);

    this.quantity_field.on('keyup change', event => {
      this.checkItemHasBids(this.quantity_field);
      this.setItemModeValue();
    });
  }

  loadItemModeValue() {
    if (this.quantity_field.val() > 1 && this.itemTypeValue() === 'silent') {
      this.showElement(this.bid_increment_type_wrapper);
    }
    else {
      this.hideAndResetItemMode();
    }
  }

  setItemModeValue() {
    if (this.quantity_field.val() > 1 && this.itemTypeValue() === 'silent') {
      this.bid_increment_type.prop( "checked", true );
      this.showElement(this.bid_increment_type_wrapper);
    }
    else {
      this.hideAndResetItemMode();
    }
  }

  hideAndResetItemMode() {
    this.bid_increment_type.prop( "checked", false );
    this.hideElement(this.bid_increment_type_wrapper);
  }



  bindfairMarketValueChanges() {
    let fairMarketValue = this.fair_market_value;
    let startingBidAmount = this.item_starting_bid_amount;
    let minimumBidIncrement = this.item_minimum_bid_increment;

    // if fair_market_value field exists while editing auction item
    if (!isNaN(fairMarketValue.val())) {
      this.calculateAndSetFieldValues(fairMarketValue, startingBidAmount, minimumBidIncrement);
    }

    fairMarketValue.on('focusout', event => {
      this.calculateAndSetFieldValues(fairMarketValue, startingBidAmount, minimumBidIncrement);
    });
  }

  checkItemHasBids(element) {
    const auction = this.container.attr('data-auction');
    const auction_item = this.container.attr('data-item');

    // returns if blank
    if (!auction_item || !auction)
      return

    const target = element.attr('name');
    const target_elem = target == 'item[quantity]' ? this.quantity_warning : this.item_type_warning;
    const value = element.val();

    // check whether the item has active bids present or not
    $.ajax({
      type: 'POST',
      url: "/manage/auctions/" + auction + "/items/" + auction_item + "/bid_status?" + target + "=" + value,
      success: (response) => {
        this.hideElement(target_elem);
      },
      error: (response) => {
        this.showElement(target_elem.html(response.responseJSON.error));
      },
    })
  }

  hideElement(element) {
    element.addClass('d-none').hide();
  }

  showElement(element) {
    element.removeClass('d-none').show();
  }

  itemTypeValue(){
    if (this.type_field.filter(':checked').length)
      return this.type_field.filter(':checked').val()
  }

  bindDateFields() {
    this.item_availability.on('change', event => {
      if (this.item_availability.filter(':checked').val() == 'scheduled_time') {
        this.showElement(this.date_wrapper);
      } else {
        this.item_start_at.val('');
        this.item_end_at.val('');
        this.hideElement(this.date_wrapper);
      }
    });
  }

  calculateAndSetFieldValues(fairMarketValue, startingBidAmount, minimumBidIncrement) {
    let itemTypeValue = this.itemTypeValue();
    const lowestStartingBidAmount = 0.01;
    const lowestMinimumBidIncrement = 0.02;
    const bidAmountMultiplier = 0.4;
    const bidIncrementMultiplier = 0.05;


    if (itemTypeValue === 'fixed_price') {
      startingBidAmount.val(0);
      minimumBidIncrement.val(0);
    } else {
      if ((itemTypeValue === 'silent') || (itemTypeValue === 'live')) {
        if (startingBidAmount.val() < lowestStartingBidAmount) {
          startingBidAmount.val(Math.ceil(fairMarketValue.val() * bidAmountMultiplier).toFixed());
        }
      }

      if (itemTypeValue === 'silent') {
        if (minimumBidIncrement.val() < lowestMinimumBidIncrement) {
          minimumBidIncrement.val(Math.ceil(fairMarketValue.val() * bidIncrementMultiplier).toFixed());
        }
      }
    }
  }

  updateFormLabels(itemType) {
    let requiredIndicators = document.querySelectorAll('label .required-indicator');

    // Hide all required indicators
    for (let elem of requiredIndicators) {
      elem.classList.add('d-none');
    }

    const requiredFields = this.container.data().requiredFieldsMap[itemType];
    const requiredLabelNames = requiredFields.map(fieldName => 'item_' + fieldName);

    // Display all required indicator for matching field names 
    for (let fieldName of requiredLabelNames) {
      const selector = 'label[for="' + fieldName + '"] .required-indicator';
      const elem = document.querySelector(selector);
      if (elem) { 
        elem.classList.remove('d-none');
      }
    }
  }
};

$(document).on('turbo:load', function() {
  const item_form = $('#auction_item_form');
  if (!(item_form.length > 0)) { return; }
  new AuctionItem(item_form);
});
